<template>
	<main class="main">
		<div class="personal">
			<div class="container personal-inner">
				<div class="row">
					<div class="personal-cnt col-md-4 offset-md-4">
						<a class="personal-logo" href="#">
							<img src="../../../../../shared/assets/styles/images/logo.svg" alt="logo">
						</a>
						<p class="personal-txt">Sign up for your free 14 day trial</p>
						<p class="personal-desrc">Start to build your form to help you capture more leads.</p>
						<validation-observer ref="observer" v-slot="{ handleSubmit }">
							<b-form class="personal-form" @submit.stop.prevent="handleSubmit(onSubmit)">
								<ValidationProvider name="Email" rules="required|email" vid="email">
									<b-form-group label="Email"
												  label-for="email"
												  slot-scope="{ valid, errors }">
										<b-form-input
												id="email"
												name="email"
												v-model="email"
												:state="errors[0] ? false : (valid ? true : null)"
												placeholder="Email">
										</b-form-input>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>

								<ValidationProvider rules="required|min:6|max:18" name="Password" vid="password">
									<b-form-group label-for="password"
											slot-scope="{ valid, errors }"
											label="Password">
										<b-form-input
												type="password"
												id="password"
												v-model="password"
												:state="errors[0] ? false : (valid ? true : null)"
												placeholder="Password">
										</b-form-input>
										<span class="pass-btn js-pass-btn" @click="switchVisibility($event, 'password')"></span>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<ValidationProvider rules="required|confirmed:password" name="Password" vid="password_confirmation">
									<b-form-group label-for="form_confirm_password"
												  slot-scope="{ valid, errors }"
												  label="Confirm Password">
										<b-form-input
												type="password"
												id="password_confirmation"
												v-model="password_confirmation"
												:state="errors[0] ? false : (valid ? true : null)"
												placeholder="Password">
										</b-form-input>
										<span class="pass-btn js-pass-btn" @click="switchVisibility($event, 'password_confirmation')"></span>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<div class="form-group">
									<div class="form-check custom-control custom-checkbox">
										<input class="form-check-input custom-control-input" id="agreeToToS" type="checkbox" v-model="terms_of_service">
										<label class="form-check-label custom-control-label" for="agreeToToS"><span>I agree to the SiteAuditor.com <a href="https://growthrobotics.com/growth-robotics-customer-terms-of-service/" target="_blank">Terms of Service</a></span></label>
									</div>
									<span class="invalid_feedback">{{terms_of_service_error}}</span>
								</div>


								<div class="form-group">
									<input class="btn btn-primary personal-btn" :disabled="savingProcessing" type="submit" value="Create Account">
								</div>
								<p class="personal-links">Already have an account?
									<router-link :to="{name: 'app.login'}"> Sign In</router-link>
								</p>
							</b-form>
						</validation-observer>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
    import {authHelperMixin} from "../../../../../shared/mixins/auth-mixin";

    export default {
        name: "Register",
        mixins: [authHelperMixin],
        data() {
            return {
                savingProcessing: false,
                email: null,
                password: null,
                password_confirmation: '',
                terms_of_service: false,

				terms_of_service_error: ''
            };
        },
        methods: {
            onSubmit() {
                if (!this.terms_of_service) {
                    this.terms_of_service_error = 'Please accept Terms and Conditions to continue';
                    return;
				}
                let sendData = {
                    'email': this.email,
                    'password': this.password,
                    'password_confirmation': this.password_confirmation,
                };

                this.savingProcessing = true;
                this.$store
                    .dispatch('auth/signUp', sendData)
                    .then(() => {
                        this.savingProcessing = false;
                        this.$router.push('/')
                    }).catch((err) => {
                    this.savingProcessing = false;
                    if (!err.response) {
                        this.showNotifications({message: 'Something went wrong!', type: 'danger'})
                    } else if (err.response.status === 422) {
                        this.$refs.observer.setErrors(err.response.data.errors);
                    } else {
                        this.showErrorNotifications(err);
                    }
                })
            },

        },

		watch: {
            terms_of_service: function () {
                this.terms_of_service_error = '';
            },
		}

    }
</script>


<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/personal";

	.invalid_feedback {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
	}
</style>


